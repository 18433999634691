/*eslint-disable */
<template>
  <b-container fluid :key="rerenderWorkFlow">
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa fa-solid fa-map-signs" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              GPaths: Career Assessment | Academic & Career Planner
            </h4>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Gpath Quiz Modal -->
    <b-modal
      v-model="showQuiz"
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
      id="xxl"
      v-if="classGrade && classGrade<=GPATHS_JUNIOR_CLASS_GRADE"
      >
        <QuestionQuiz v-if="loadCareerQuiz" :propModalTitle="'Career Assessment'" :propAppId="GPathData.GPATH_CAREER08" @emitNextTab="goToNextTab()"></QuestionQuiz>
        <QuestionQuiz v-if="loadSkillQuiz" :propModalTitle="'Skill Assessment'" :propAppId="GPathData.GPATH_SKILL08" @emitNextTab="goToNextTab()"></QuestionQuiz>
        <QuestionQuiz v-if="loadAptitudeQuiz" :propModalTitle="'Aptitude Assessment'" :propAppId="GPathData.GPATH_APTITUDE08" @emitNextTab="goToNextTab()"></QuestionQuiz>
        <QuestionQuiz v-if="loadCounsellorQuiz" :propModalTitle="'Counsellor Assessment'" :propAppId="GPathData.GPATH_COUNSELLOR08" @emitNextTab="goToNextTab()"></QuestionQuiz>
    </b-modal>

    <b-modal
      v-model="showQuiz"
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      hide-footer
      id="xxl"
      v-else
      >
        <QuestionQuiz v-if="loadCareerQuiz" :propModalTitle="'Career Assessment'" :propAppId="GPathData.GPATH_CAREER" @emitNextTab="goToNextTab()"></QuestionQuiz>
        <QuestionQuiz v-if="loadSkillQuiz" :propModalTitle="'Skill Assessment'" :propAppId="GPathData.GPATH_SKILL" @emitNextTab="goToNextTab()"></QuestionQuiz>
        <QuestionQuiz v-if="loadAptitudeQuiz" :propModalTitle="'Aptitude Assessment'" :propAppId="GPathData.GPATH_APTITUDE" @emitNextTab="goToNextTab()"></QuestionQuiz>
        <QuestionQuiz v-if="loadCounsellorQuiz" :propModalTitle="'Counsellor Assessment'" :propAppId="GPathData.GPATH_COUNSELLOR" @emitNextTab="goToNextTab()"></QuestionQuiz>
    </b-modal><!-- Gpath Quiz Modal -->

    <!-- Alert Box for payment not made -->
    <AlertBox :propAlertTitle = "alertTitle" :propAlertDesc = "alertMsg" v-if="showAlert" @emitCloseAlertModel="emitCloseAlertModel" @emitConfirmAlertModel="emitConfirmAlertModel"/>
    <!-- Alert Box for payment not made -->

    <div>
      <div class="iq-card showFlexListDiv">
        <!-- Tabs visible in student view only -->
        <div class="user-tabing showFlexList">
          <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
            <div  class="flex-fill p-0" @click="switchTab(cvTabObj.personalDetail)">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.personalDetail" id="pills-personalDetail-tab" href="#pills-personalDetail-tab"
                ariaControls="pills-personalDetail-tab" role="tab" :ariaSelected="selectedTab === cvTabObj.personalDetail" :title="cvTabObj.personalDetail" />
            </div>

            <div  class="flex-fill p-0" @click="switchTab(cvTabObj.makePayment)" v-if="cvTabObj.makePayment">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.makePayment" id="pills-makePayment-tab" href="#profile-makePayment"
                ariaControls="pills-career" role="tab" :ariaSelected="selectedTab === cvTabObj.makePayment" :title="cvTabObj.makePayment" />
            </div>

            <div  class="flex-fill p-0"  @click="switchTab(cvTabObj.career)">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.career" id="pills-career-tab" href="#profile-career"
                ariaControls="pills-career" role="tab" :ariaSelected="selectedTab === cvTabObj.career" :title="cvTabObj.career" />
            </div>

            <div  class="flex-fill p-0" @click="switchTab(cvTabObj.skill)">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.skill" id="pills-skill-tab" href="#profile-skill"
                ariaControls="pills-skill" role="tab" :ariaSelected="selectedTab === cvTabObj.skill" :title="cvTabObj.skill" />
            </div>

            <div class="flex-fill p-0" @click="switchTab(cvTabObj.aptitude)">
              <tab-nav-items  class="flex-fill p-0"  :active="selectedTab === cvTabObj.aptitude" id="pills-aptitude-tab"  href="#pills-aptitude"
                ariaControls="pills-aptitude" role="tab" :ariaSelected="selectedTab === cvTabObj.aptitude" :title="cvTabObj.aptitude" />
            </div>

            <div class="flex-fill p-0" @click="switchTab(cvTabObj.counsellor)">
              <tab-nav-items  class="flex-fill p-0"  :active="selectedTab === cvTabObj.counsellor" id="pills-counsellor-tab"  href="#pills-counsellor"
                ariaControls="pills-counsellor" role="tab" :ariaSelected="selectedTab === cvTabObj.counsellor" :title="cvTabObj.counsellor" />
            </div>
          </tab-nav>
        </div><!-- Tabs visible in student view only -->
      </div>

      <div class="iq-card showFlexListDiv" :key="classGrade">
        <b-container fluid class="p-0">
          <!-- Show 8th and Below -->
          <b-row v-if="classGrade <= GPATHS_JUNIOR_CLASS_GRADE" class="m-0 Documents" :key="classGrade">
            <div class="m-3 table_scroll">
              <p style="color: #FF9900;" v-if="warnMsg">
                 <i class="ri-error-warning-line" style="display: inline;"></i>
                 {{warnMsg}}
                  <!-- <span class="educationAddIcon" @click="showAddEducation"><i class="fa-solid fa-circle-plus"></i></span> -->
              </p>
              <!-- Personal Information -->
              <b-col md="12" class="Assessmwnt_skills" v-show="(selectedTab === cvTabObj.personalDetail || selectedTab === cvTabObj.review)">
                <PersonalInfo v-if="studentData && Object.keys(studentData).length" :propUserId="studentData.user_id" :propUserData="studentData" :propModuleType="'ADM'" :propShowPersonalInfoCard="false" @emitRefreshUserDetails="emitRefreshUserDetails"/>
              </b-col>

              <b-col md="12" class="Assessmwnt_skills" v-show="(selectedTab === cvTabObj.personalDetail || selectedTab === cvTabObj.review)">
                <h4>
                  <span style="border-bottom: 1px solid; border-bottom-color: currentcolor; border-color: var(--iq-border-light); padding-bottom: 10px; margin-bottom: 10px !important; font-size: 18px; font-weight: 500;">Education</span>
                  <i @click="showAddEducation()" class="fa-solid fa-circle-plus float-right primary-color"></i>
                </h4>
                <hr class="m-1"/>
                <div class="ml-2 mt-2 pb-4">
                  <span>
                    <i class="fa-solid fa-people-roof primary-color" aria-hidden="true"></i>&nbsp;&nbsp;Class&nbsp;
                    <span v-if="classGrade">{{ classGrade }}<span class="float-right pointer" title="Edit Education Profile" @click="gotoUserProfile()"><i class="fa-solid ri-pencil-line primary-color ml-0" aria-hidden="true"></i></span></span>
                    <span v-else><b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="gotoUserProfile()">Missing</b-badge></span>
                  </span><br>
                  <span>
                    <i class="fa-solid fa-school primary-color" aria-hidden="true"></i>
                    <span v-if="schoolName">&nbsp;&nbsp;{{ schoolName.replaceAll("undefined,", "") }}</span>
                    <span v-else>&nbsp;&nbsp;School&nbsp;<b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="gotoUserProfile()">Missing</b-badge></span>
                  </span>
                </div>
              </b-col>
            </div>
            <template v-if="addEducationShow">
              <Education :propEducationForm="educationForm" :propEducationAddShow='addEducationShow' @emitCloseEducationModal="closeEducationModal" @emitUserProfileEducation="addEducation"></Education>
            </template>

            <!-- Make Payment -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_MakePayment'" v-show="(selectedTab === cvTabObj.makePayment || selectedTab === cvTabObj.review)" v-if="cvTabObj.makePayment">
              <div class="mt-3">
                <Paygw :propFetchUserBillingDetails="refreshUserBillingDetailsInPayGw" :propModuleType="modName" :propModuleName="modName" :propRedirect="'gpath_home'" :propSubModuleName="subModuleName" :propModuleObjId="gpathPlanModuleObjId" :propTrackRevenueFor="gpathSession && gpathSession.gps_id" @emitPaymentStatus="emitPaymentStatus" :propSrc="modName" :propSrcId="newSessionId"/>
              </div>
            </b-col>

            <!-- Career Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Career'" v-show="(selectedTab === cvTabObj.career || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_CAREER08" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>

            <!-- Skill Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Skill'" v-show="(selectedTab === cvTabObj.skill || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_SKILL08" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>

            <!-- Aptitude Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Aptitude'" v-show="(selectedTab === cvTabObj.aptitude || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_APTITUDE08" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>

            <!-- Counsellor Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Counsellor'" v-show="(selectedTab === cvTabObj.counsellor || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_COUNSELLOR08" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>
          </b-row><!-- Show 8th and Below -->

          <!-- Show 8th Above -->
          <b-row v-else class="m-0 Documents">
            <div class="m-3 table_scroll">
              <p style="color: #FF9900;" v-if="warnMsg">
                 <i class="ri-error-warning-line" style="display: inline;"></i>
                 {{warnMsg}}
              </p>
              <!-- Personal Information -->
              <b-col md="12" class="Assessmwnt_skills" v-show="(selectedTab === cvTabObj.personalDetail || selectedTab === cvTabObj.review)">
                <PersonalInfo v-if="studentData && Object.keys(studentData).length" :propUserId="studentData.user_id" :propUserData="studentData" :propModuleType="'ADM'" :propShowPersonalInfoCard="false" @emitRefreshUserDetails="emitRefreshUserDetails"/>
              </b-col>

              <b-col md="12" class="Assessmwnt_skills" v-show="(selectedTab === cvTabObj.personalDetail || selectedTab === cvTabObj.review)">
                <h4>
                  <span style="border-bottom: 1px solid; border-bottom-color: currentcolor; border-color: var(--iq-border-light); padding-bottom: 10px; margin-bottom: 10px !important; font-size: 18px; font-weight: 500;">Education</span>
                  <i @click="showAddEducation()" class="fa-solid fa-circle-plus float-right primary-color"></i>
                </h4>
                <hr class="m-1"/>
                <div class="ml-2 mt-2 pb-4">
                  <span>
                    <i class="fa-solid fa-people-roof primary-color" aria-hidden="true"></i>&nbsp;&nbsp;Class&nbsp;
                    <span v-if="classGrade">{{ classGrade }}<span class="float-right pointer" title="Edit Education Profile" @click="gotoUserProfile()"><i class="fa-solid ri-pencil-line primary-color ml-0" aria-hidden="true"></i></span></span>
                    <span v-else><b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="gotoUserProfile()">Missing</b-badge></span>
                  </span><br>
                  <span>
                    <i class="fa-solid fa-school primary-color" aria-hidden="true"></i>
                    <span v-if="schoolName">&nbsp;&nbsp;{{ schoolName.replaceAll("undefined,", "") }}</span>
                    <span v-else>&nbsp;&nbsp;School&nbsp;<b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="gotoUserProfile()">Missing</b-badge></span>
                  </span>
                </div>
              </b-col>
            </div>
            <template v-if="addEducationShow">
              <Education :propEducationForm="educationForm" :propEducationAddShow='addEducationShow' @emitCloseEducationModal="closeEducationModal" @emitUserProfileEducation="addEducation"></Education>
            </template>

            <!-- Make Payment -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_MakePayment'" v-show="(selectedTab === cvTabObj.makePayment || selectedTab === cvTabObj.review)" v-if="cvTabObj.makePayment">
              <div class="mt-3">
                <Paygw :propFetchUserBillingDetails="refreshUserBillingDetailsInPayGw" :propModuleType="modName" :propModuleName="modName" :propRedirect="'gpath_home'" :propSubModuleName="subModuleName" :propModuleObjId="gpathPlanModuleObjId" :propTrackRevenueFor="gpathSession && gpathSession.gps_id" @emitPaymentStatus="emitPaymentStatus" :propSrc="modName" :propSrcId="newSessionId"/>
              </div>
            </b-col>

            <!-- Career Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Career'" v-show="(selectedTab === cvTabObj.career || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_CAREER" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>

            <!-- Skill Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Skill'" v-show="(selectedTab === cvTabObj.skill || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_SKILL" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>

            <!-- Aptitude Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Aptitude'" v-show="(selectedTab === cvTabObj.aptitude || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_APTITUDE" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>

            <!-- Counsellor Quiz -->
            <b-col md="12" class="p-0 Assessmwnt_skills" :key="userObj.spcm_id+'_Counsellor'" v-show="(selectedTab === cvTabObj.counsellor || selectedTab === cvTabObj.review)">
              <div class="mt-3">
                <GpathCareerAssesmentDetails :propParentComponentName="`GPathWorkflow`" :propAppId="GPathData.GPATH_COUNSELLOR" @emitStartGpathQuiz = "emitStartGpathQuiz"/>
              </div>
            </b-col>
          </b-row><!-- Show 8th Above -->
        </b-container>
        <!-- Next Button -->
        <div class="row mt-2 mb-2 row w-100" v-if="showNextTab">
          <div class="col-12 pr-2" v-if="selectedTab != cvTabObj.skill && selectedTab != cvTabObj.career && selectedTab != cvTabObj.aptitude && selectedTab != cvTabObj.counsellor && selectedTab != cvTabObj.makePayment">
            <b-button size="sm" variant="primary" class="pull-right primary mb-3" @click="goToNextTab()" >
              Next
            </b-button>
          </div>
        </div>
        <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
          <div v-html="toastMsg"></div>
        </b-toast>
      </div>
    </div>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit.js"
import { User } from "../../../FackApi/api/user.js"
import PersonalInfo from "../../../components/UserProfile/PersonalInfo.vue"
import Education from "../../../components/UserProfile/Education.vue"
import QuestionQuiz from "../Quiz/QuestionQuiz.vue"
import GPathData from "../../../FackApi/json/GPath.json"
import Paygw from "../Paygw/Paygw.vue"
import { GPathSessions } from "../../../FackApi/api/GPathSession"
import { UserParent } from "../../../FackApi/api/userParent.js"
import GpathCareerAssesmentDetails from "./GpathQuizDetail.vue"
import AlertBox from "../../../components/AlertBox.vue"
import { Affiliates } from "../../../FackApi/api/Affiliate.js"
import cacheLs from "../../../store/cacheLs.js"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "GPaths",
  components: {
    PersonalInfo,
    Education,
    QuestionQuiz,
    AlertBox,
    Paygw,
    GpathCareerAssesmentDetails
  },
  data () {
    return {
      GideProducts: GideProducts,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      modName: GideProducts.GPATHS,
      subModuleName: "",
      newSessionId: "",
      toastTitle: "GPaths Career Assessment",
      userObj: {},
      user_profile: {},
      extraCurricularListObj: [],
      educationListObj: [],
      cvTabObj: {
        personalDetail: "Personal",
        makePayment: "Payment",
        career: GPathData.GPATH_WORKFLOW_TAB_CAREER_ASSESSMENT,
        skill: GPathData.GPATH_WORKFLOW_TAB_SKILL_ASSESSMENT,
        aptitude: GPathData.GPATH_WORKFLOW_TAB_APTITUDE_ASSESSMENT,
        counsellor: GPathData.GPATH_WORKFLOW_TAB_EXTRA_ASSESSMENT
      },
      addEducationShow: false,
      educationForm: null,
      selectedTab: "Personal",
      gpathPlanModuleObjId: GideProducts.GPATHS,
      studentData: {},
      IS_MOBILE_DEVICE: true,
      GPathData: GPathData,
      paymentStatus: null,
      showNextBtn: false,
      warnMsg: null,
      showCareerQuizModal: false,
      showSkillQuizModal: false,
      showAptitudeQuizModal: false,
      showCounsellorQuizModal: false,
      loadAptitudeQuiz: false,
      loadSkillQuiz: false,
      loadCareerQuiz: false,
      loadCounsellorQuiz: false,
      showQuiz: false,
      showAlert: false,
      alertMsg: "",
      alertTitle: "",
      cvbtnModalClose: "Close",
      rerenderWorkFlow: null,
      classGrade: null,
      schoolName: "",
      GPATHS_JUNIOR_CLASS_GRADE: 8,
      showNextTab: false,
      refreshUserBillingDetailsInPayGw: 0
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    userToken () {
      return this.$store.getters["User/getToken"]
    },
    gpathSession () {
      return this.$store.getters["GpathSession/gpathSession"]
    }
  },
  async mounted () {
    socialvue.index()
    if (window.innerWidth > 991) {
      this.IS_MOBILE_DEVICE = false
    }

    await this.viewUserProfile()
    await this.updatePaymentStatusInLs()
    await this.checkUserGpathPayment()
    this.redirectToTab()
  },
  methods: {
    /**
     * emitCloseAlertModel
     */
    emitCloseAlertModel () {
      this.showAlert = false
    },
    /**
     * emitConfirmAlertModel
     */
    emitConfirmAlertModel () {
      this.showAlert = false
    },
    /**
     * closeModalBox
     */
    closeModalBox () {
      this.showQuiz = false
    },
    /**
     * emitStartGpathQuiz
     */
    emitStartGpathQuiz (loadGpathQuiz, alertMsg, alertTitle) {
      // if (process.env.VUE_APP_ENV == "local") {
      //   loadGpathQuiz = true
      // }

      if (loadGpathQuiz) {
        this.showQuiz = true
      }
      else {
        this.alertMsg = alertMsg
        this.alertTitle = alertTitle
        this.showAlert = true
      }
    },
    /**
     * redirectToTab
     * Redirects user to the correct tab based on session_state
     */
    redirectToTab () {
      switch (this.gpathSession.session_state) {
        case GPathData.GPATH_STATE_INITIATED:
          this.selectedTab = this.cvTabObj.personalDetail
          break
        case GPathData.GPATH_STATE_PERSONAL:
          if (this.cvTabObj.makePayment) {
            this.selectedTab = this.cvTabObj.makePayment
          }
          else {
            this.selectedTab = this.cvTabObj.career
          }
          break
        case GPathData.GPATH_STATE_PAID:
          this.selectedTab = this.cvTabObj.career
          this.showCareerQuizModal = true
          this.loadCareerQuiz = true
          break
        case GPathData.GPATH_STATE_CAREER:
          this.selectedTab = this.cvTabObj.skill
          this.showSkillQuizModal = true
          this.loadSkillQuiz = true
          break
        case GPathData.GPATH_STATE_SKILL:
          this.selectedTab = this.cvTabObj.aptitude
          this.showAptitudeQuizModal = true
          this.loadAptitudeQuiz = true
          break
        case GPathData.GPATH_STATE_APTITUDE:
          this.selectedTab = this.cvTabObj.counsellor
          this.showCounsellorQuizModal = true
          this.loadCounsellorQuiz = true
          break
        default:
          this.selectedTab = this.cvTabObj.personalDetail
          break
      }

      let tabname = encodeURI(this.selectedTab)
      window.history.pushState(null, "Gide", `/gpath_home#${tabname}`)
    },
    /**
     * emitPaymentStatus
     */
    async emitPaymentStatus (paymentStatus, isAffiliated = false) {
      if (paymentStatus == "Captured") {
        // If payment status is captured, it means that the  payment is successful

        // Refresh user's gpath session to capture the payment status
        await this.viewGpathSession()
        if (isAffiliated) {
          this.loadCareerQuiz = true
        }
      }
    },
    /**
     * viewGpathSession
     */
    async viewGpathSession () {
      try {
        // See if the session for the user is created or not
        let payload = {
          user_id: this.userData.user_id
        }
        let gpathSessionViewResp = await GPathSessions.gpath_sessionView(this, null, payload)

        if (gpathSessionViewResp && !gpathSessionViewResp.resp_status) {
          // clearing user's session in local storage
          cacheLs.delete_gpath_session()
          this.$store.dispatch("GpathSession/setGpathSession", {})
          return
        }

        // storing the count of sessions to decide whether to show dashboard or not
        gpathSessionViewResp.resp_data.data.report_count = gpathSessionViewResp.resp_data_count ? gpathSessionViewResp.resp_data_count : 0
        gpathSessionViewResp.resp_data.data.session_state = GPathData.GPATH_STATE_PAID
        gpathSessionViewResp.resp_data.data.session_state_desc = GPathData.GPATH_STATE_PAID_MSG

        // storing the sessions details in local storage
        await this.$store.dispatch("GpathSession/setGpathSession", gpathSessionViewResp.resp_data.data)

        // Edit GpathSession
        let gpsEditObj = {
          user_id: this.userData.user_id,
          gps_id: this.gpathSession.gps_id,
          session_state: GPathData.GPATH_STATE_PAID,
          session_state_desc: GPathData.GPATH_STATE_PAID_MSG
        }
        GPathSessions.gpath_sessionEdit(this, gpsEditObj)
      }
      catch (error) {
        console.error("Exception in viewGpathSession() ", error.message)
      }
    },
    /**
     * emitRefreshUserDetails
     */
    async emitRefreshUserDetails () {
      this.viewUserProfile()
    },
    /**
     * updatePaymentStatusInLs
     * updates the payment_id in local storage because there is a delay in the webhook that is preventing the updation on emitPaymentStatus
     */
    async updatePaymentStatusInLs () {
      if (this.gpathSession.session_state == GPathData.GPATH_STATE_PAID) {
        let payload = {
          user_id: this.userData.user_id
        }

        let gpathSessionViewResp = await GPathSessions.gpath_sessionView(this, null, payload)

        if (gpathSessionViewResp && !gpathSessionViewResp.resp_status) {
          // clearing user's session in local storage
          cacheLs.delete_gpath_session()
          this.$store.dispatch("GpathSession/setGpathSession", {})
        }
        else {
          gpathSessionViewResp.resp_data.data.report_count = gpathSessionViewResp.resp_data_count ? gpathSessionViewResp.resp_data_count : 0
          gpathSessionViewResp.resp_data.data.session_state = GPathData.GPATH_STATE_PAID
          gpathSessionViewResp.resp_data.data.session_state_desc = GPathData.GPATH_STATE_PAID_MSG

          // storing the sessions details in local storage
          await this.$store.dispatch("GpathSession/setGpathSession", gpathSessionViewResp.resp_data.data)
        }
      }
    },
    /**
     * checkUserGpathPayment
     */
    async checkUserGpathPayment () {
      if (this.gpathSession && this.gpathSession.aff_code) {
        // User Already have a discount code
        // since we need to update the gpath_session id into the affiliate table, so checking this condition first because when a user is invieted, the pay_tid and the aff_code both the fields have aff_code in them
        if (this.gpathSession.session_state <= GPathData.GPATH_STATE_PERSONAL && this.gpathSession.session_state >= GPathData.GPATH_STATE_INITIATED) {
          let affObj = {
            participant_id: this.userData.user_id,
            aff_code: this.gpathSession.aff_code,
            module_object_id: this.gpathSession.gps_id
          }

          let affEditResp = await Affiliates.AffiliateEdit(this, affObj)
          if (!affEditResp.resp_status) {
            this.toastTitle = "Discount Code Validation Error"
            this.toastMsg = "There was some error while validating your discount code. Kindly use the correct code or reach out to your counsellor for the correct code"
            this.toastVariant = "danger"
            this.showToast = true
          }
        }

        this.paymentStatus = true
        const newTabObj = { ...this.cvTabObj }
        delete newTabObj.makePayment
        this.cvTabObj = newTabObj
      }
      else if (this.gpathSession && this.gpathSession.pay_tid) {
        // User Already Paid. No need to check Affiliate Code
        this.paymentStatus = true
        const newTabObj = { ...this.cvTabObj }
        delete newTabObj.makePayment
        this.cvTabObj = newTabObj
      }
      else {
        // check for payment, if the payment exists update the session data
        let checkGpathPaymentResp = await GPathSessions.gpath_session_check_payment(this, { gps_id: this.gpathSession.gps_id, module_object_id: GideProducts.GPATHS })
        if (checkGpathPaymentResp.resp_status) {
          checkGpathPaymentResp = checkGpathPaymentResp.resp_data
          this.viewGpathSession()
          const newTabObj = { ...this.cvTabObj }
          delete newTabObj.makePayment
          this.cvTabObj = newTabObj
        }
        else {
          this.cvTabObj.makePayment = "Payment"
        }

        if (this.gpathSession.session_state >= GPathData.GPATH_STATE_PERSONAL) {
          this.selectedTab = this.cvTabObj.makePayment
        }
      }
    },
    /**
     * checkPersonalDetails
     */
    async checkPersonalDetails () {
      const userParentListResp = await UserParent.user_parentList(this, this.userData.user_id)
      // No need to check for the user_gender because when a new user starts the assessment right after completing the quick profile, his/her gender is not available in userData store variable
      // @TODO: Subho fix the above issue
      if (this.userData.user_name &&
          this.userData.user_email &&
          this.userData.user_mobile &&
          // this.userData.user_gender &&
          userParentListResp.resp_status) {
        this.warnMsg = null
        return true
      }
      else {
        this.warnMsg = "Please fill your personal and parent details. Parent details are required to share the GPaths Report after the assessment is completed."
        return false
      }
    },
    /**
     * showAddEducation
     */
    showAddEducation () {
      this.addEducationShow = true
      this.educationForm = {
        subjects: [],
        videoLinks: [],
        attachments: []
      }
    },
    /*
     * addEducation
     */
    async addEducation (payload) {
      if (!this.user_profile) {
        this.user_profile = {}
      }

      if (!this.user_profile.education) {
        this.user_profile.education = []
      }

      const educationIndex = this.user_profile.education.findIndex(edu => edu.id === payload.newEducation.id)
      if (educationIndex >= 0) {
        this.user_profile.education[educationIndex] = payload.newEducation
      }
      else {
        this.user_profile.education.unshift(payload.newEducation)
      }

      this.user_profile.user_id = this.userData.user_id
      this.extraCurricularListObj[payload.newEducation.id] = payload.extraCurricularList
      this.addEducationShow = false

      this.classGrade = this.user_profile.education[0].education.class_name
      this.schoolName = this.user_profile.education[0].institute_name

      this.viewUserProfile()
    },
    /**
     * closeEducationModal
     */
    closeEducationModal () {
      this.addEducationShow = false
    },
    /**
     * setGpathSessions
     */
    async setGpathSessions (sessionState, sessionDescription) {
      let payload = {
        user_id: this.userData.user_id,
        gps_id: this.gpathSession.gps_id,
        session_state: sessionState,
        session_state_desc: sessionDescription
      }
      let setGpathSessionResp = await GPathSessions.gpath_sessionEdit(this, payload)
      if (setGpathSessionResp && setGpathSessionResp.resp_status) {
        // Updating the store with the new state
        this.$store.dispatch("GpathSession/setGpathSession", { ...this.gpathSession, session_state: sessionState, session_state_desc: sessionDescription })
      }
    },
    /**
     * viewUserProfile
     */
    async viewUserProfile () {
      this.studentData = this.userData
      this.userObj.user_id = this.studentData.user_id

      const userProfile = await User.userProfileView(this, this.userObj.user_id)
      if (userProfile.resp_status) {
        this.extraCurricularListObj = userProfile.resp_data.data.extraCurricularListObj
        this.educationListObj = userProfile.resp_data.data.user_profile.education

        if (!(this.educationListObj && this.educationListObj[0] && this.educationListObj[0].education && this.educationListObj[0].education.class_name)) {
          // Education Class is Missing
          this.classGrade = null
          this.warnMsg = "Please complete your education profile"
          return
        }

        if (userProfile.resp_data.data.user_profile) {
          if (typeof userProfile.user_profile === "string") {
            this.user_profile = JSON.parse(userProfile.resp_data.data.user_profile)
          }
          else {
            this.user_profile = userProfile.resp_data.data.user_profile
          }
        }

        // Find the Highest Class
        let highestClass = this.educationListObj[0].education.class_name
        let highestClassIndex = 0
        for (let i in this.educationListObj) {
          let j = parseInt(i) + 1

          if (!this.educationListObj[j]) {
            break
          }

          if (highestClass < this.educationListObj[j].education.class_name) {
            highestClassIndex = j
            highestClass = this.educationListObj[j].education.class_name
          }
        }

        // Set the classGrade to load relevant Quizzes
        console.error("XXXX classGrade", highestClass, process.env.VUE_APP_ENV, this.GPATHS_JUNIOR_CLASS_GRADE) // latest class is alwasy at index 0
        this.classGrade = highestClass
        this.schoolName = `${this.educationListObj[highestClassIndex].institute_name}, ${this.educationListObj[highestClassIndex].institute_city}, ${this.educationListObj[highestClassIndex].institute_state}, ${this.educationListObj[highestClassIndex].institute_country}`

        // Checking the user's personal details, without which he cannot proceed further
        if (this.selectedTab == this.cvTabObj.personalDetail) {
          // Update the session state only if the current session_state is < GPATH_STATE_PERSONAL
          if (await this.checkPersonalDetails() && this.gpathSession.session_state <= this.GPathData.GPATH_STATE_PERSONAL) {
            this.setGpathSessions(this.GPathData.GPATH_STATE_PERSONAL, this.GPathData.GPATH_STATE_PERSONAL_MSG)
          }
          else {
            this.showNextTab = false
            return
          }
        }
      }
      else {
        this.warnMsg = "Please fill your personal and parent details. Parent details are required to share the GPaths Report after the assessment is completed."
        this.showNextTab = false
        return
      }

      this.showNextTab = true
    },
    /**
     * goToNextTab
     */
    async goToNextTab () {
      // Reset all the quizes
      this.loadAptitudeQuiz = false
      this.loadSkillQuiz = false
      this.loadCareerQuiz = false
      this.loadCounsellorQuiz = false

      switch (this.selectedTab) {
        case this.cvTabObj.personalDetail:
          if (await this.checkPersonalDetails()) {
            if (this.gpathSession.session_state <= this.GPathData.GPATH_STATE_INITIATED) {
              this.setGpathSessions(this.GPathData.GPATH_STATE_PERSONAL, this.GPathData.GPATH_STATE_PERSONAL_MSG)
            }
            if (this.cvTabObj.makePayment) {
              this.refreshUserBillingDetailsInPayGw = this.refreshUserBillingDetailsInPayGw + 1
              this.selectedTab = this.cvTabObj.makePayment
            }
            else {
              if (this.gpathSession.session_state <= this.GPathData.GPATH_STATE_PERSONAL) {
                this.setGpathSessions(this.GPathData.GPATH_STATE_PAID, this.GPathData.GPATH_STATE_PAID_MSG)
              }
              this.loadCareerQuiz = true
              this.selectedTab = this.cvTabObj.career
            }
          }
          break
        case this.cvTabObj.makePayment:
          if (this.showNextBtn && this.paymentStatus) {
            this.loadCareerQuiz = true
            this.selectedTab = this.cvTabObj.career
          }
          break
        case this.cvTabObj.career:
          this.setGpathSessions(this.GPathData.GPATH_STATE_CAREER, this.GPathData.GPATH_STATE_CAREER_MSG)
          this.loadSkillQuiz = true
          this.selectedTab = this.cvTabObj.skill
          break
        case this.cvTabObj.skill:
          this.setGpathSessions(this.GPathData.GPATH_STATE_SKILL, this.GPathData.GPATH_STATE_SKILL_MSG)
          this.loadAptitudeQuiz = true
          this.selectedTab = this.cvTabObj.aptitude
          break
        case this.cvTabObj.aptitude:
          this.setGpathSessions(this.GPathData.GPATH_STATE_APTITUDE, this.GPathData.GPATH_STATE_APTITUDE_MSG)
          this.loadCounsellorQuiz = true
          this.selectedTab = this.cvTabObj.counsellor
          break
        case this.cvTabObj.counsellor:
          this.setGpathSessions(this.GPathData.GPATH_STATE_COMPLETED, this.GPathData.GPATH_STATE_COMPLETED_MSG)
          this.$router.push("/gpath_report_generate")
          break
      }
      this.showQuiz = false

      let tabname = encodeURI(this.selectedTab)
      window.history.pushState(null, "Gide", `/gpath_home#${tabname}`)
    },
    /**
     * switchTab
     */
    async switchTab (tabName) {
      // Reset all the quizes
      this.loadAptitudeQuiz = false
      this.loadSkillQuiz = false
      this.loadCareerQuiz = false
      this.loadCounsellorQuiz = false

      switch (tabName) {
        case this.cvTabObj.personalDetail:
          if (this.gpathSession.session_state >= GPathData.GPATH_STATE_INITIATED) {
            this.selectedTab = tabName
          }
          break
        case this.cvTabObj.makePayment:
          if (this.gpathSession.session_state >= GPathData.GPATH_STATE_PERSONAL) {
            this.selectedTab = tabName
            this.refreshUserBillingDetailsInPayGw = this.refreshUserBillingDetailsInPayGw + 1
          }
          else {
            this.rerenderWorkFlow = Math.random()
          }
          break
        case this.cvTabObj.career:
          this.loadCareerQuiz = true
          this.selectedTab = tabName
          break
        case this.cvTabObj.skill:
          this.loadSkillQuiz = true
          this.selectedTab = tabName
          break
        case this.cvTabObj.aptitude:
          this.loadAptitudeQuiz = true
          this.selectedTab = tabName
          break
        case this.cvTabObj.counsellor:
          this.loadCounsellorQuiz = true
          this.selectedTab = tabName
          break
      }

      this.warnMsg = null

      let tabname = encodeURI(this.selectedTab)
      window.history.pushState(null, "Gide", `/gpath_home#${tabname}`)
    },
    /**
     * showAddEducation
     */
    gotoUserProfile () {
      window.open(`/user_edit/${this.userData.user_id}#profile`, "_blank")
    }
  }
}
</script>

<style lang="scss">
  .educationAddIcon {
    color: var(--iq-primary);;
  }
  .dropdown-item{
    color: #212529 !important;
  }
  .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover{
    background: transparent;
    color: var(--iq-primary) !important;
  }
  .logo-icon{
    height: 50px !important;
    width: 50px !important;
    overflow: hidden;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%;
  }
  .w-full{
    width: 100%;
  }
  .btn-opacity{
    border: none ;
    outline : none;
    background:#fff;
    width: 100%;
  }
  .btn-opacity:hover{
    color: var(--iq-primary)
  }
  .line-wrap{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr)
  }
  @media screen and (max-width: 768px){
    .grid{
       grid-template-columns: 0.9fr 1.2fr 0.9fr
    }
  }
  #photo-grid img {
    background: #FFFFFF!important;
    object-fit: fill;
  }
  .leftArrowIcon {
    position: absolute;
    left: 0px;
    z-index: 99;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 32px;
    background-color: transparent !important;
    border: none;
    i {
      font-size: 22px;
    }
  }
  .rightArrowIcon {
    position: absolute;
    right: 0px;
    z-index: 99;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 32px;
    background-color: transparent !important;
    border: none;
    i {
      font-size: 22px;
    }
  }
  .review_form_spacingSPCM {
    padding: 11px !important;
  }
    .career {
    .container {
      padding: 0;
    }
  }
  .questionContainer {
    white-space: normal;
    height: 100%;
    width: 100%;
  .optionContainer {
      margin-top: 0px;
      flex-grow: 1;
      .option {
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 5px 10px 5px 50px;
        cursor: pointer;
        border: transparent 1px solid #f1f1f1;
        position: relative;
        border: 1px solid #f1f1f1;
        overflow: hidden;
        font-size: 18px;
        height: 40px;
        display: inline-table;
        width: 100%;
        &.is-selected {
          border-color: var(--iq-primary);
          background-color: white;
        }
        &.is-correct {
          border-color: rgb(92, 180, 92);
        }
      }
    }
    .questionFooter {
      width: 100%;
      align-self: flex-end;
      .pagination {
        //padding: 10px 15px;
        margin: 15px 25px;
      }
      .progressContainer {
        // margin: 15px 25px;
      }
    }
  }
  .optionNo {
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 40px;
  background-color: #f1f1f1;
  left: 0px;
  }
  .optionNo span {
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 17px;
    height: 23px;
    font-weight: 500;
    line-height: initial;
    font-size: 18px;
  }

  #pills-tab-1{
    li a{
    border-radius: 0px !important;
    }
  }
  #pills-tab-1.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active{
    border-bottom:1px solid !important;
    }
  @media screen and (max-width: 991px){
    .right_data{
      position: absolute;
      top: 0;
      bottom: 0;
      background: #00000038;
      min-height: 100%;
      margin: auto;
      height:max-content;
      max-width: 100%;
      z-index: 99999;
    }
    .right_data_center{
      position: absolute;
      top: 10px;
      height: max-content;
      margin: auto;
      background: #fff;
      left: 10px;
      right: 10px;
      min-height: 305px;
    }
    .main-circle{
      float: right;
      font-size: 20px;
      display: block !important;
    }
    .menu_icon_right{
      position: absolute;
      right: 8px;
      top: 12px;
    }
    .menu_icon_left{
      position: absolute;
      left: 8px;
      top: 12px;
    }
  }
  @media screen and (min-width: 991px){
  .right_data{
    display: block;
    }
  }
  .main-circle{
    display: none;
  }
  .close_right_data{
    float: right;
    font-size: 19px !important;
    position: absolute;
    top: 3px;
    right: 6px;
  }
  .close_left_data{
    float: right;
    font-size: 19px !important;
    position: absolute;
    top: 5px;
    right: 6px;
  }
.Documents{
  display: block;
  .document_hr_color{
    border-color: var(--iq-primary) !important;
  }
  .document_card{
    padding-left: 0px !important;
    padding-right:0px !important;
    box-shadow: none !important;
  }
}
.Assessmwnt_skills .questionRow{
  box-shadow: none !important;
}
.Assessmwnt_skills .questionContainer, .Assessmwnt_skills .document_card .Gpath_heading{
  padding: 0px !important;
}
.Assessmwnt_skills .table_scroll, .Assessmwnt_skills .table_scroll div:first-child, .Assessmwnt_skills .questionContainer .sidebarContainer, .Assessmwnt_skills .questionContainer .sidebarContainer .tag.is-rounded{
  margin: 0px !important;
  padding-bottom: 0px;
}
.Assessmwnt_skills .questionContainer .questionRow .que{
  padding: 0px !important;
}
.Assessmwnt_skills .educationCard.viewEducationCard .multi_education_div{
  margin-left: 0px !important;
}
.Assessmwnt_skills .educationCard .educationAddIcon{
  top:-2px
}
.Assessmwnt_skills .educationCard.viewEducationCard .multi_education_div .instituteTitle_block, .Assessmwnt_skills .document_card .Gpath_heading h6{
  border-bottom: 1px solid;
  border-color: var(--iq-border-light);
  padding-bottom: 10px;
  margin-bottom: 10px !important;
  font-size: 18px;
  font-weight: 500;
}
.Assessmwnt_skills .document_card .Gpath_heading h6{
  margin-top:5px ;
}
#xxl header{
  display: none;
}
.Assessmwnt_skills .Gpath_Assessment_skills.container-fluid{
  padding: 0px;
}
.add_education{
  font-weight: 500 !important;
}
</style>
